/* You can add global styles to this file, and also import other style files */
@import './assets/scss/style';

// external
@import '~chartist/dist/chartist.css';
@import '~c3/c3.min.css';
@import '~datatables.net-dt/css/jquery.dataTables.css';
@import '~ngx-toastr/toastr.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
@import '~angular-calendar/css/angular-calendar.css';
@import '~dragula/dist/dragula.css';
@import '~@swimlane/ngx-datatable/release/index.css';
@import '~@swimlane/ngx-datatable/release/themes/material.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';
@import "~jsoneditor/dist/jsoneditor.min.css";

// @import './assets/vendor/css-chart.css';
.ace_editor.ace-jsoneditor {
  min-height: 600px;
}

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800');
@font-face {
  font-family: 'Sugary';
  src: url('https://micuento-fonts.s3.eu-west-1.amazonaws.com/sugary-pancake/sugary-pancake/sugary-pancake-webfont.eot');
  src: url('https://micuento-fonts.s3.eu-west-1.amazonaws.com/sugary-pancake/sugary-pancake-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://micuento-fonts.s3.eu-west-1.amazonaws.com/sugary-pancake/sugary-pancake-webfont.woff2') format('woff2'),
       url('https://micuento-fonts.s3.eu-west-1.amazonaws.com/sugary-pancake/sugary-pancake-webfont.woff') format('woff'),
       url('https://micuento-fonts.s3.eu-west-1.amazonaws.com/sugary-pancake/sugary-pancake-webfont.ttf') format('truetype'),
       url('https://micuento-fonts.s3.eu-west-1.amazonaws.com/sugary-pancake/sugary-pancake-webfont.svg#sugary_pancakeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}