//This is for the icons (Required file)
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import 'assets/scss/icons/themify-icons/themify-icons.css';
@import '~flag-icon-css/css/flag-icon.min.css';
@import "~@mdi/font/css/materialdesignicons.min.css";
@import "assets/scss/icons/crypto-icons/cryptocoins.css";  

// this is for the perfect scrollbar
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

.status {
    color: red;
    &.active {
      color: green;
    }
  }
  
.edit {
    font-size: 20px;
    cursor: pointer;
}

.select:hover {
    background-color: #536dc444;
    cursor: pointer;
}

.draggable {
  cursor: grab;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dragging {
  cursor: grabbing;
  background: white;
  position: relative;
  width: 80%;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 10;
  transition: none;
}